<template>
  <Loading v-if="isLoading" />
  <div v-else class="messages-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("messages.contact_us") }}</h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'sender_name'" class="text-nowrap">
            {{ $t("messages.sender_name") }}
          </span>
          <span v-else-if="props.column.label === 'email'" class="text-nowrap">
            {{ $t("messages.email") }}
          </span>
          <span v-else-if="props.column.label === 'phone'" class="text-nowrap">
            {{ $t("messages.phone") }}
          </span>
          <span
            v-else-if="props.column.label === 'sender_type'"
            class="text-nowrap"
          >
            {{ $t("messages.sender_type") }}
          </span>
          <span
            v-else-if="props.column.label === 'subject'"
            class="text-nowrap"
          >
            {{ $t("messages.subject") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("messages.status") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <router-link
            :to="
              globalPermission.contacts &&
              globalPermission.contacts.show && {
                name: 'show-message',
                params: { id: props.row.id },
              }
            "
          >
            <span v-if="props.column.label === 'status'" class="text-nowrap">
              <b-badge variant="light-success" v-if="props.row.is_closed">{{
                $t("messages.closed")
              }}</b-badge>
              <b-badge variant="light-danger" v-else>{{
                $t("messages.open")
              }}</b-badge>
            </span>
            <span
              class="text-nowrap custom-toggle"
              v-else-if="props.column.field === '#'"
            >
              {{ props.row.index }}
            </span>
            <span
              v-else-if="props.column.field === 'sender_type'"
              class="text-nowrap"
            >
              {{ $t(`messages.${props.row.sender_type}`) }}
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </router-link>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import { BPagination, BCard, BBadge } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  name: "MessagesList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    Loading,
    BBadge,
    OverlayComponent,
  },
  data() {
    return {
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "sender_name",
          field: "name",
        },
        {
          label: "email",
          field: "email",
          sortable: false,
        },
        {
          label: "phone",
          field: "phone",
          sortable: false,
        },
        {
          label: "sender_type",
          field: "sender_type",
          sortable: false,
        },
        {
          label: "subject",
          field: "subject",
          sortable: false,
        },
        {
          label: "status",
          field: "status",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getMessagesList();
  },
  methods: {
    async getMessagesList() {
      try {
        const response = (await this.$http.get("admin/contacts")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
