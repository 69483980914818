var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"messages-list"},[_c('b-card',[_c('div',{staticClass:"p-2 custom-search d-flex align-items-center justify-content-between mb-1"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("messages.contact_us")))])]),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"search-options":{
        enabled: true,
        skipDiacritics: true,
        placeholder: _vm.$t('search_placeholder'),
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'sender_name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.sender_name"))+" ")]):(props.column.label === 'email')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.email"))+" ")]):(props.column.label === 'phone')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.phone"))+" ")]):(props.column.label === 'sender_type')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.sender_type"))+" ")]):(props.column.label === 'subject')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.subject"))+" ")]):(props.column.label === 'status')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("messages.status"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [_c('router-link',{attrs:{"to":_vm.globalPermission.contacts &&
            _vm.globalPermission.contacts.show && {
              name: 'show-message',
              params: { id: props.row.id },
            }}},[(props.column.label === 'status')?_c('span',{staticClass:"text-nowrap"},[(props.row.is_closed)?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(_vm._s(_vm.$t("messages.closed")))]):_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(_vm._s(_vm.$t("messages.open")))])],1):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'sender_type')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(("messages." + (props.row.sender_type))))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])])],2)],1),_c('overlay-component',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }